<template>
  <div id="TicketingShowsPros">
    <card style="grid-column: 2 / span 2">
      <qs-kpi qlik-id="jkfgBHP" class="single-kpi" />
    </card>

    <QsCard
      :qlik-ids="['ZnyFP']"
      style="grid-column: 1 / span 4"
      card-style="compact"
    />

    <QsCard :qlik-ids="['HKNpCmG']" style="grid-column: 1 / span 2" />
    <QsCard :qlik-ids="['FJDhyb']" style="grid-column: 3 / span 2" />

    <QsCard :qlik-ids="['DCKCjQ']" style="grid-column: 1 / span 2" />
    <QsCard :qlik-ids="['cmyste']" style="grid-column: 3 / span 2" />

    <QsCard :qlik-ids="['TjmQKy']" style="grid-column: 1 / span 4" />
  </div>
</template>

<script>
import Card from "@/components/Card";
import QsCard from "@/components/Qlik/QsCard";
import QsKpi from "@/components/Qlik/QsKpi";

export default { components: { Card, QsCard, QsKpi } };
</script>

<style scoped>
#TicketingShowsPros {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 100px 45px repeat(2, 300px) 400px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}

.single-kpi {
  font-size: 22px;
}
</style>
